@use "../utils/mixins";
@use "../utils/variables";

.content_preview {

    h2 {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 24px;
            line-height: 24px;
            font-weight: variables.$bold;

            margin: 0 0 16px 0;

        }

    }

    ul {

        @include mixins.breakpoint() {
    
            list-style: inside disc;
    
            margin: 0 0 16px 16px;
    
        }
    
    }

}
