@use "sass:math";
@use "sass:color";
@use "../utils/mixins";
@use "../utils/variables";


.ReactTable {
	position:relative;
	.loader {
		display: flex;
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color: rgba(255,255,255,0.5);
		justify-content: center;
		align-items: center;
		z-index:999;
	}
}
.data__table {

    @include mixins.breakpoint() {

        width: 100%;

        border: solid 1px variables.$light_gray;

    }

    &.margin-top {
        @include mixins.breakpoint() {
            margin: 16px 0 0 0;
        }
    }

    //  COMMON
    thead,
    tbody {

        tr {

            th,
            td {

                @include mixins.breakpoint() {

                    padding: 10px;

                    font-size: 14px;
                    line-height: 20px;

                    text-align: left;
                    vertical-align: middle;
					word-wrap: break-word;


                }

            }

        }

    }

    //  THEAD
    thead,
    .data__table-head {

        @include mixins.breakpoint() {

            color: variables.$white;
            background-color: variables.$dark_midnight_blue;

            font-weight: variables.$bold;

            display: table-header-group;

            @include mixins.user-select(none);

        }

        tr,
        .data__table-row {

            @include mixins.breakpoint() {
                display: table-row;
            }

            th,
            .data__table-cell {

                @include mixins.breakpoint() {
                    display: table-cell;
                }

            }

        }

    }

    //  TBODY
    & > tbody {

        & > tr {

            @include mixins.breakpoint() {
                border-bottom: solid 1px variables.$light_gray;
            }

            &:nth-child(odd) {
                @include mixins.breakpoint() {
                    background-color: rgba(variables.$light_gray, 0.5);
                }
            }

            &:hover {
                @include mixins.breakpoint() {
									background-color: color.adjust(variables.$light_gray, $lightness: -0.5%)
                }
            }

            &:nth-child(even) {
                @include mixins.breakpoint() {

                }
            }

            td {

                @include mixins.breakpoint() {

                    font-weight: variables.$medium;

                    border-right: solid 1px variables.$light_gray;

                }

                &:only-of-type {

                    @include mixins.breakpoint() {
                        text-align: center;
                    }

                }

                &:last-child {

                    @include mixins.breakpoint() {

                        border-right: none;

                    }

                }

                a {

                    @include mixins.breakpoint() {
                        color: variables.$dark_midnight_blue;
                    }

                }

				button {
					@include mixins.breakpoint() {
						border: 0!important;
						cursor: pointer;
						background: none;
						text-decoration: underline;
						font: inherit;
						padding: 0;
						color: variables.$dark_midnight_blue;
					}
				}

            }

        }

        &.scrollable {

            @include mixins.breakpoint() {

                height: 500px;
                overflow-y: auto;
                overflow-x: hidden;

            }

        }

    }

    //  TABLE EXCEPTIONS
    thead {

        tr {

            th {

				@for $i from 1 through 16 {

					@for $j from 1 through 16 {

						@if ($i > $j) or ($i == $j) or ( ( math.round(calc($j/$i)) == calc($j/$i) ) and not ( $j > $i ) ) {
							//  DO NOTHING
						} @else {

							&.is-#{$i}-of-#{$j} {

								@include mixins.breakpoint() {
									flex: none;
									width: math.percentage(calc($i/$j));
								}

							}

						}

					}

				}

                &.table-checkbox-cell {

                    @include mixins.breakpoint() {
                        text-align: center;
                    }

                }

                &.table-id-cell {

                    @include mixins.breakpoint() {
                        text-align: center;
                    }

                }

            }

        }

    }
	.__react_component_tooltip {
		position: fixed;
		opacity: 1;
		top:0;
		left: 0;
		overflow: initial;
		border-radius: 3px;

		&.place-top:before {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			bottom: -8px;
			left: 50%;
			margin-left: -10px;
		}
		&.place-top:after {
			border-top-color: #222;
			border-top-style: solid;
			border-top-width: 6px;
		}
	}

}

//  COLGROUPS
colgroup {

    col {

        &.col__checkbox {

            @include mixins.breakpoint() {
                width: 18px;
            }

        }
        &.col__id {

            @include mixins.breakpoint() {
                width: 40px;
                text-align: center;
            }

        }

    }

}



.data__table-footer {

    @include mixins.breakpoint() {

        padding: 16px 0 0 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .table__navigation {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

        }

        .table__pagination {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;

                margin: 0 16px 0 0;

                border: solid 1px variables.$light_gray;

            }

            li {

                @include mixins.breakpoint() {

                    border-right: solid 1px variables.$light_gray;

                }

                &:last-child {

                    @include mixins.breakpoint() {

                        border-right: none;

                    }

                }

                button {

                    @include mixins.breakpoint() {

                        color: variables.$black;

                        width: 38px;
                        height: 38px;
						border: 0!important;
						cursor: pointer;

                        text-align: center;
                        text-decoration: none;

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;


                    }

                    &.disabled {

                        @include mixins.breakpoint() {

                            opacity: 0.25;
                            pointer-events: none;

                        }

                    }

                    &.is-active {

                        @include mixins.breakpoint() {

                            color: variables.$white;
                            background-color: variables.$dark_midnight_blue;

                        }

                        &:hover {

                            @include mixins.breakpoint() {

                                color: variables.$white;
                                background-color: variables.$dark_midnight_blue;

                            }

                        }

                    }

                    &:hover {

                        @include mixins.breakpoint() {

                            background-color: variables.$light_gray;

                        }

                    }

                    .material-icons-outlined {

                    }

                }

            }

        }

        .table__page-select-container {

            @include mixins.breakpoint() {
                width: 80px;
            }

        }

    }

    .table__info {

        @include mixins.breakpoint() {

            text-align: right;

            color: variables.$black;

            font-size: 16px;
            line-height: 40px;
            font-weight: variables.$bold;

        }

    }

}

.filters {

    @include mixins.breakpoint() {

        padding: 16px;
        margin: 0 0 16px 0;

        background-color: variables.$light_gray;

    }

	label {
		@include mixins.breakpoint() {
			margin-bottom: 12px;
		}
	}

	.or {
		@include mixins.breakpoint() {
			font-size: 18px;
		}

		&:after {
			@include mixins.breakpoint() {
				background: #efefef!important;
			}
		}
	}
	.top {
		@include mixins.breakpoint() {
			margin-top: 28px;
		}
	}

}

.pre__table {

    @include mixins.breakpoint() {
        margin: 0 0 16px 0;
    }

}

.association__status {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        padding: 10px 0;

    }

    li {

        @include mixins.breakpoint() {

            border-right: solid 1px variables.$medium_gray;

            white-space: nowrap;

            padding: 0 8px;

            font-size: 16px;
            line-height: 20px;

        }

        &:last-child {

            @include mixins.breakpoint() {

                border: none;

            }

        }

        strong {

            @include mixins.breakpoint() {
                font-weight: variables.$bold;
            }

        }

    }

}

//  SORTING
.data__table {

    thead {

        tr {

            th {

                &.rt-resizable-header {

                    @include mixins.breakpoint() {

                        cursor: pointer;
                        position: relative;

                        @include mixins.user-select(none);

                    }

                    .rt-resizable-header-content,
                    .material-icons-outlined {

                        @include mixins.breakpoint() {

                            display: inline-block;
                            vertical-align: middle;

                        }

                    }

                    .material-icons-outlined {

                        @include mixins.breakpoint() {

                            width: 18px;
                            height: 22px;

                            line-height: 22px;
                            text-align: center;

                            position: absolute;
                            right: 8px;

                        }

                    }

                }

            }

        }

    }

}

//  DATA TABLE COLUMN WIDTHS / SPECIFIC TABLE
.data__table {

    thead {

        tr {

            th {

                @include mixins.breakpoint() {
                    white-space: nowrap;
					width: 100%;
                }

                &.table-checkbox-cell {

                    @include mixins.breakpoint() {
                        width: 38px;
                    }

                    label {
                        @include mixins.breakpoint() {
                            width: 18px;
                        }
                    }

                }

            }

        }

    }

}

//  DATA TABLE COLUMN WIDTHS BASED ON COLGROUPS
.data__table {

	&.normal-overflow {
		thead {
			th{
				&.primary{
					width:60px;
					text-align: center;
				}
			}
		}
		tbody {

			tr {

				td {
					overflow: initial!important;
					&.primary{
						width: 60px;
						height: 60px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}

    @include mixins.breakpoint() {
        table-layout: fixed;
    }

    &.not-fixed {

        @include mixins.breakpoint() {
            table-layout: auto;
        }

    }

    colgroup {

        col {

            &.col_checkbox {
                @include mixins.breakpoint() {
                    width: 40px;
                }
            }

			&.col_centered_checkbox {
				@include mixins.breakpoint() {
					width: 90px;
				}
			}

            &.col_id {
                @include mixins.breakpoint() {
                    width: 70px;
                }
				&.clubs {
					@include mixins.breakpoint() {
						width: 70px;
					}
				}
				&.golfers {
					@include mixins.breakpoint() {
						width: 90px;
					}
				}
            }

            &.col_assoc-name,
            &.col_club-name,
            &.col_golfer-name,
            &.col_course-name,
            &.col_transaction-name,
			&.col_user-name{
                @include mixins.breakpoint() {
                    width: 220px;
                }
            }

            &.col_membership-code{
                @include mixins.breakpoint() {
                    width: 175px;
                }
            }

            &.col_is_locked{
                @include mixins.breakpoint() {
                    width: 115px;
                }
            }

            &.col_bill_rate{
                @include mixins.breakpoint() {
                    width: 120px;
                }
            }

            &.col_buttons{
                @include mixins.breakpoint() {
                    width: 140px;
                }
            }

            &.col_golfer-name-short {
                @include mixins.breakpoint() {
                    width: 140px;
                }
            }

            &.col_status {
                @include mixins.breakpoint() {
                    width: 70px;
                }
            }
						&.col_join_pages_actions {
							@include mixins.breakpoint() {
									width: 230px;
							}
						}

            &.col_club_join_pages_action {
                @include mixins.breakpoint() {
                    width: 115px;
                }
            }

			&.col_extended-status {
				@include mixins.breakpoint() {
					width: 76px;
				}
			}
			&.col_handicap-index {
				@include mixins.breakpoint() {
					width: 70px;
				}
			}

            &.col_city {
                @include mixins.breakpoint() {
                    width: 140px;
                }
            }

			&.col_addons_enabled {
				@include mixins.breakpoint() {
					width: 120px;
				}
			}

            &.col_state {
                @include mixins.breakpoint() {
                    width: 100px;
                }
            }

            &.col_country {
                @include mixins.breakpoint() {
                    width: 150px;
                }
            }

            &.col_phone-number {
                @include mixins.breakpoint() {
                    width: 120px;
                }
            }

            &.col_requirements {
              @include mixins.breakpoint() {
                width: 180px;
              }
            }

          &.col_eligibility {
            @include mixins.breakpoint() {
              width: 200px;
            }
          }

            &.col_email-address {
				width:250px;
            }

			&.col_description {
				width: 300px;
			}

            &.col_liaison {

            }

            &.col_date {
                @include mixins.breakpoint() {
                    width: 110px;
                }
            }

            &.col_fee_date {
              @include mixins.breakpoint() {
                width: 100px;
              }
            }

						&.col_fee {
                @include mixins.breakpoint() {
                    width: 75px;
                }
            }

            &.col_age {
                @include mixins.breakpoint() {
                    width: 50px;
                }
            }

            &.col_gender_eligibility {
                @include mixins.breakpoint() {
                    width: 105px;
                }
            }

            &.col_club_fee_date {
                @include mixins.breakpoint() {
                    width: 100px;
                }
            }

            &.col_edited_date {
                @include mixins.breakpoint() {
                    width: 155px;
                }
            }

            &.col_membership_name {
                @include mixins.breakpoint() {
                    width: 150px;
                }
            }

            &.col_ojr {
              @include mixins.breakpoint() {
                width: 50px;
              }
            }

						&.col_fee_name {
                @include mixins.breakpoint() {
                    width: 155px;
                }
            }

            &.col_date_edited {
                @include mixins.breakpoint() {
                    width: 180px;
                }
            }

            &.col_membership_code {
                @include mixins.breakpoint() {
                  width: 150px;
                }
            }

            &.col_date_posted {
                @include mixins.breakpoint() {
                    width: 260px;
                }
            }
            &.col_date_and_time {
                @include mixins.breakpoint() {
                    width: 180px;
                }
            }
            &.col_offer_date {
                @include mixins.breakpoint() {
                  width: 97px;
                }
            }
						&.col_offer_range_date {
                @include mixins.breakpoint() {
                  width: 138px;
                }
            }
            &.col_offer_description {
                @include mixins.breakpoint() {
                  width: 91px;
                }
            }
            &.col_offer_type {
                @include mixins.breakpoint() {
                  width: 65px;
                }
            }
            &.col_offer_status {
                @include mixins.breakpoint() {
                  width: 65px;
                }
            }
            &.col_club_discount {
                @include mixins.breakpoint() {
                  width: 70px;
                }
            }
            &.col_auto_apply {
                @include mixins.breakpoint() {
                  width: 65px;
                }
            }
            &.col_offer_applied_to {
                @include mixins.breakpoint() {
                  width: 106px;
                }
            }
            &.col_offer_times_used {
                @include mixins.breakpoint() {
                  width: 70px;
                }
            }
            &.col_offer_discount_amount {
                @include mixins.breakpoint() {
                  width: 70px;
                }
            }
            &.col_offer_id {
                @include mixins.breakpoint() {
                  width: 170px;
                }
            }
            &.col_offer_actions {
                @include mixins.breakpoint() {
                    width: 153px;
                }
            }
            &.col_archived_offer_date {
                @include mixins.breakpoint() {
                    width: 97px;
                }
            }
            &.col_archived_offer_code {
                @include mixins.breakpoint() {
                    width: 120px;
                }
            }
            &.col_archived_offer_type {
                @include mixins.breakpoint() {
                    width: 50px;
                }
            }
            &.col_archived_offer_discount_amount {
                @include mixins.breakpoint() {
                    width: 75px;
                }
            }
            &.col_archived_offer_club_discount {
                @include mixins.breakpoint() {
                    width: 78px;
                }
            }
            &.col_archived_offer_status {
                @include mixins.breakpoint() {
                    width: 60px;
                }
            }
            &.col_archived_offer_applied_to {
                @include mixins.breakpoint() {
                    width: 106px;
                }
            }
            &.col_archived_offer_usage {
                @include mixins.breakpoint() {
                    width: 80px;
                }
            }
            &.col_change_code,
            &.col_method,
            &.col_username {
                @include mixins.breakpoint() {
                    width: 160px;
                }
            }

            &.col_fee_description {
              @include mixins.breakpoint() {
                width: 150px;
              }
            }

            &.col_method-small {
                @include mixins.breakpoint() {
                    width: 120px;
                }
            }

            &.col_method-extra-small {
                @include mixins.breakpoint() {
                    width: 100px;
                }
            }

            &.col_type {
                @include mixins.breakpoint() {
                    width: 110px;
                }
            }

            &.col_player-name {
                @include mixins.breakpoint() {
                    width: 240px;
                }
            }

            &.col_hi,
            &.col_holes {
                @include mixins.breakpoint() {
                    width: 60px;
                }
            }
			&.col_addons_status {
				@include mixins.breakpoint() {
					width: 70px;
				}
			}

			&.col_hidden{
				display: none;
			}
            &.col_ghin {
                @include mixins.breakpoint() {
                    width: 140px;
                }
            }

						&.col_date_applied {
                @include mixins.breakpoint() {
                    width: 96px;
                }
            }

            &.col_local-id {
                @include mixins.breakpoint() {
                    width: 140px;
                }
            }

            &.col_gender {
                @include mixins.breakpoint() {
                    width: 100px;
                }
            }

          &.col_details {
            @include mixins.breakpoint() {
              width: 170px;
            }
          }

            &.col_membership-type {

            }

            &.col_slope {
                @include mixins.breakpoint() {
                    width: 100px;
                }
            }

						&.col_score,
						&.col_diff,
						&.col_pcc,
						&.col_esr {
							@include mixins.breakpoint() {
							  width: 80px;
							}
						}

						&.col_ghin_number{
							width: 90px;
						}

						&.col_used {
							@include mixins.breakpoint() {
								width: 10px;
							}
						}

						&.col_other_adj {
							@include mixins.breakpoint() {
								width: 120px;
							}
						}

						&.col_note {
                @include mixins.breakpoint() {
                    width: 300px;
                }
            }
						&.col_id_assoc {
								@include mixins.breakpoint() {
										width: 90px;
								}
						}

            &.col_min {
                @include mixins.breakpoint() {
                    width: 80px;
                }
            }
			&.col_actions_centered {
				@include mixins.breakpoint() {
					width: 100px;
				}
			}

			&.col_actions_centered_w130 {
				@include mixins.breakpoint() {
					width: 130px;
				}
			}
            &.col_pico {
                @include mixins.breakpoint() {
                    width: 50px;
                }
            }

            &.col_tee {
                @include mixins.breakpoint() {
                    width: 160px;
                }
            }

            &.col_edit {
                @include mixins.breakpoint() {
                    width: 45px;
                }
            }


            &.col_delete {
                @include mixins.breakpoint() {
                    width: 65px;
                }
            }

			&.col_remove {
				@include mixins.breakpoint() {
					width: 68px;
				}
			}

            &.col_nickname {
                @include mixins.breakpoint() {
                    width: 200px;
                }
            }

            &.col_url {
                @include mixins.breakpoint() {
                    width: 280px;
                }
            }

        }

    }

    tbody {

        tr {

            td, span {

                //@include breakpoint() {
                //    overflow: hidden;
                //    text-overflow: ellipsis;
                //    white-space: nowrap;
				//
                //    position: relative;
                //}
				.text-holder {
					white-space: nowrap;
					overflow: hidden;

					&.col_wrap {
						white-space: normal;
					}

					&.default-tee {
						overflow: visible;
					}
				}


                //  IN TABLE SYMBOLS
                .material-icons-outlined {

                    @include mixins.breakpoint() {

                        font-size: 18px;
                        line-height: 18px;

                    }

                    &.blue {
                        @include mixins.breakpoint() {
                            color: variables.$blue;
                            cursor: pointer;
                        }
                        &:hover {
                            @include mixins.breakpoint() {
															  color: color.adjust(variables.$blue, $lightness: -10%)
                            }
                        }
                    }

                    &.td-align-right {
                        @include mixins.breakpoint() {
                            position: absolute;
                            right: 10px;
                        }
                    }

                    &.td-align-center {
                        @include mixins.breakpoint() {
                            position: relative ;
                            top: 4px;
                            left: 50%;
                            @include mixins.transform(translate3d(-50%,0,0));
                        }
                    }

                }

				td, span {
					&.col_edit-delete {
						padding: 0!important;
						margin: 0!important;
						line-height: 20px;
						height: 20px;
						align-items: center;
					}
				}

				&.col_edit-delete {
					@include mixins.breakpoint() {
						width: auto;
						display: flex;
						flex-direction: row;
						justify-content: space-around;
						margin-right: 0!important;
					}
				}

				&.col_long-text{
					white-space: initial;
					overflow: initial;
				}

                //  TABLE CELLS COLORS
                &.yellow_highlight {
                    @include mixins.breakpoint() {
                        background-color: variables.$yellow_highlight;
                    }
                }

            }

        }

    }

    //  ALINMENTS
    thead,
    tbody {

        tr {

            th,
            td {
                &.table-align-center-no-padding {
                  @include mixins.breakpoint() {
                    padding: 0px;
                    text-align: center;
                  }
                }

                &.table-align-center {
                    @include mixins.breakpoint() {
                        text-align: center;
                    }
                }
								&.table-align-right {
                    @include mixins.breakpoint() {
                        text-align: right;
                    }
                }

				span {
					display: inline-flex;
				}

            }

        }

    }

}

//  LOADER SETUP
.data-table__loader {

    @include mixins.breakpoint() {

        width: 120px;
        height: 40px;

        display: inline-block;

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: initial;

        background-image: url('../img/loader.svg');

    }

}

//  TABLE LEGEND
.table__legend {

    h5 {
      margin-top: 10px;
    }

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

        padding: 0 8px;
        border: solid 1px variables.$light_gray;

    }

    li {

        @include mixins.breakpoint() {

            white-space: nowrap;

            color: variables.$dark_gray;

            font-size: 14px;
            line-height: 38px;

            margin: 0 16px 0 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .material-icons-outlined {

                @include mixins.breakpoint() {
                    margin: 0 8px 0 0;
                }

            }

            .legend-item {

                @include mixins.breakpoint() {

                    display: inline-block;

                    width: 32px;
                    height: 16px;

                    border: solid 1px variables.$light_gray;

                    margin: 0 16px 0 0;

                }

                &.yellow_highlight {
                    @include mixins.breakpoint() {
                        background-color: variables.$yellow_highlight;
                    }
                }

            }

        }

        &:last-child {

            @include mixins.breakpoint() {
                margin: 0;
            }

        }
		span {
			font-weight: bold;
			margin: 0 3px;
		}
    }

}

.table__legend__checkboxes {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;

  .vertical_line {
    border-left: 1px solid #333333;
    font-size: 16px;
    height: 24px;
  }
}

.checkboxes_title {
  font-weight: 600;
}
