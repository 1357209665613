@use "utils/mixins";
@use "utils/variables";


.tokens-toggle {
	position: absolute;
	width: 16px;
	height: 16px;
	background-size: contain;
	background-repeat: no-repeat;
	&.opened {
		background-image: url("../img/up.svg");
	}
	&.closed {
		background-image: url("../img/down.svg");
	}

}
.data-tokens {
	.token {
		font-size: 13px ;
		padding: 3px 10px;
		margin-bottom: 10px;
		margin-right: 5px;
	}
}

.capitalize {
	text-transform: capitalize;
}
.col-report-type {
	width: 60%;
}
.col-report-user, .col-export-date {
	width: 80%;
}

.th-wrap {
	width: 120px!important;
	white-space: initial!important;
}

.clubs-special-update__loader {
	display: block;
	margin:  0 auto;
}
.special-update__warning {
	color: #C9243F;
	margin-top: 15px;
}

#image-redirect-url {
	border-color: #999;
	border: solid 1px #cccccc;
	font-size: 16px;
	line-height: 24px;
	padding: 7px 8px;
	display: block;
	height: 40px;
}

.col-billing-cycle {
	width: 30%;
}

.col-report-user_name {
	width: 50%;
}

.col-billing-date {
	width: 45% !important;
}

.padding-16-y {
	padding-top: 16px;
	padding-bottom: 16px;
}

.hr-margin-24-top {
	margin-top: 24px;
}

.hr-margin-24-bottom {
	margin-bottom: 24px;
}

.hr-margin-16-bottom {
	margin-bottom: 16px;
}

.drag-drop-item_name {
  position: relative;
  top: -7px;
  left: 7px;
}

.collapse-arrow {
  color: black;
  position: relative;
  top: 7px;
}

.collapse-block {
  background: #efefef;
  padding: 15px !important;
  margin-top: -15px;
}

.message-align {
  position: absolute;
  top: 12px;
  right: 10px;
}

.icon-position {
  margin-right: 3px;
  font-size: 16px;
}

.note {
  background-color: #ffffcb;
  padding: 15px;
}

.table-left-cell {
  text-align: right !important;
  width: 37%;
  background-color: rgba(239, 239, 239, 0.5);
  border: 1px solid #d9d9d9;
}

.no-background .table-left-cell {
  background-color: #fff;
}

.table-right-cell {
  text-align: left !important;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.table-center-cell {
  text-align: center !important;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 0!important;
}

.manual-error {
  border: 1px solid variables.$cardinal;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.dotted_border {
  background-image: linear-gradient(to right, #ccc 70%, transparent 30%), linear-gradient(to left, #ccc 70%, transparent 30%), linear-gradient(to bottom, #ccc 70%, transparent 30%), linear-gradient(to top, #ccc 70%, transparent 30%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 10px 1.5px, 10px 1.5px, 1.5px 10px, 1.5px 10px;
}

.fit_image {
  width: fit-content;
  margin: 0 auto;
}

.image_placeholder {
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 15px auto;
  width: 450px;
}
.join_link_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.join_link_image_box {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  border: variables.$random_gray;
  border-style: solid;
  padding: 15px;
}

.edit_join_link_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.small_table_button {
  width: 65px;
  height: 30px;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
}
.pd-l-13px{
	padding-left: 12px;
}

.pd-r-13px{
	padding-right: 12px;
}

.club-icons-legend {
  padding: 0px 4px;
}

.club-icons-legend-icon {
  padding-right: 2px;
  margin-top: -2px;
}

.float_right {
  float: right;
}

.medium-margin-top {
  margin-top: 30px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.border_box {
  &.centered{
    text-align: center;
  }

  line-height: 40px;
  font-family: 'National', sans-serif;
  border-radius: 4px;
  padding: 0 16px;
  justify-content: center;
  border: 1px;
  border-style: solid;
  border-color: gray;
}

.center_join_pages_navigation {
  width: fit-content;
  margin: 0 auto;
}

.panel__hr {
  margin-left: -16px;
  margin-right: -16px;
  border: 0;
  border-top: 1px solid #cccccc;
}

.hidden{
  display: none;
}

.fit_image_webkit {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.usga_logo {

  @include mixins.breakpoint() {

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      color: variables.$dark_midnight_blue;

      font-size: 13px;
      line-height: 12px;
      font-weight: variables.$bold;

      text-transform: uppercase;

      @include mixins.user-select(none);

      height: 100%;
      background-color: variables.$white;

      position: relative;
      z-index: 9;
      cursor: pointer;

  }
  @include mixins.breakpoint(variables.$small_desktop) {

      font-size: 16px;
      line-height: 16px;

  }

  &::before {

      @include mixins.breakpoint() {

          content: '';
          display: block;

          width: 80px;
          height: 24px;

          background-repeat: no-repeat;
          background-attachment: scroll;
          background-position: center;

          background-size: contain;

          background-image: url('../img/usga_logo.svg');

          margin: 0 8px 0 0;

      }
      @include mixins.breakpoint(variables.$small_desktop) {

          width: 90px;
          height: 32px;

      }

  }

}

.join-pages-sentry-logo {
  display: block;

  height: 50px;
  width: auto;

  margin: 0 0 0 auto;

  @include mixins.breakpoint(variables.$small_tablet) {
    margin: 0 0 0 32px;
  }
}

.center-join-pages-logos{
  display: flex;
  justify-content: center;
}

.cmd-button {
  cursor: pointer;
  margin-left: 6px;
}

.black-text{
  color: variables.$black;
}

.no-wrap{
  white-space: nowrap;
}

.width-860px{
	width: 860px;
}

.fr-box ul {
  list-style: disc;
}

.red-note {
  color: variables.$amaranth_red;
  font-size: 16px;
	line-height: 1;
}

.blue_ribbon {
  display: flex;

}

.ribbon_text {
  color: #999;
  font-size: 14px;
  white-space: nowrap;

  i {
    margin-right: 6px;
  }

  p {
    font-size: 15px;
  }
}

.blue_ribbon.ribbon_hover .material-icons-outlined.blue:hover {
  cursor: pointer;
  color: #025b9f !important;
}

.blue_ribbon .material-icons-outlined {
  vertical-align: middle;
  color: #0089ce!important;
  font-size: 18px;
  display: flex;
  align-self: center;
}

.blue_ribbon:not(.ribbon_hover) .material-icons-outlined {
  display: flex;
  align-self: center;
}

.transaction_legend {
  margin-top: 16px;
}

.changed-column {
  color: #da1a31 !important;
}

.text_overflow .text-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block!important;
}

.text_overflow span {
  display: contents !important;
}

.panel__head .red-note {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  margin-left: auto;
}

.regular_font .text-holder {
  font-size: 14px !important;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subparagraph {
  margin: 16px 0 0 8px;
}

.textarea-club-details {
  border-radius: 10px 10px 0 0;
  min-height: 150px;
  padding: 20px 20px;
}

.textarea-club-details:hover {
  border-color:#cccccc!important;
}

.pending-count-circle {
 position: absolute;
 top: 50%;
 left: -10px;
 transform: translateY(-50%);
 width: 25px;
 height: 25px;
 border-radius: 50%;
 border: 1px solid white;
 background-color: orange;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 14px;
 font-weight: bold;
 box-shadow: 0 0 5px gray;
 z-index: 1;

 &.top {
  top: -15px;
  left: 0px;
  transform: translateX(200%);
 }
}

.refund-hr {
  background-color: #d9d9d9;
  border: none;
  height: 1px;
  margin-bottom: 20px;
}

.refund-btn {
  border-radius: 0;
  border: 1px solid #c2c3c2;
  padding: 9px 0;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  background-color: #f2f2f2;
}

.refund-btn.full-refund {
  margin-right: 3px;
}

.refund-btn.partial-refund {
  margin-left: 3px;
}

.refund-btn.selected {
  background-color: #b9e4f6;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.strong {
  font-weight: 700 !important;
}

.refund-fee {
  display: inline-flex;
  margin-bottom: 5px;
  line-height: 40px;
  span {
    color: black;
    margin-left: 6px;
  }

  input {
    display: inline-flex;
    margin-top: 1px;
  }
}

.inactivate-golfer {
  margin-bottom: 0;
  margin-left: auto;
  margin-top: 15px;
  line-height: inherit;
}

.refund-limit-note {
  text-align: end;
  font-style: italic;
  font-size: 13px;
  margin-top: 5px;
}

.refund-input {
  font-size: 14px!important;
}

.exit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 10px;
}

.exit-icon {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.margin-top-34 {
  margin-top: 34px !important;
}

.padding-10-y {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-image-note {
  font-size: 13px;
  margin-left: 27px;
  font-style: italic;
  margin-top: -8px;
  line-height: 13px;
}

.score-number_of_played_holes {
  vertical-align: sub;
  font-style: italic;
  font-size: smaller;
}

.blue_ribbon {
  display: flex;
}

.ribbon_text {
  color: #999;
  font-size: 14px;
  white-space: nowrap;

  i {
    margin-right: 6px;
  }

  p {
    font-size: 15px;
  }
}

.blue_ribbon.ribbon_hover .material-icons-outlined.blue:hover {
  cursor: pointer;
  color: #025b9f !important;
}

.blue_ribbon .material-icons-outlined {
  vertical-align: middle;
  color: #0089ce!important;
  font-size: 18px;
  display: flex;
  align-self: center;
}

.blue_ribbon:not(.ribbon_hover) .material-icons-outlined {
  display: flex;
  align-self: center;
}

.offer_legend {
  margin-top: 8px;
}

.offer_join_type {
  li {
    margin-right: 30px;
  }
}

.italic-placeholder::placeholder {
  font-style: italic;
  font-size: 14px;
}

.under_note {
  font-size: 13px;
  font-style: italic;
  line-height: 13px;
  margin-top: 4px;
}

.icon_label {
  flex: initial!important;
  justify-content: center!important;
  padding-right: 3px!important;
}

.icon_label {
  flex: initial!important;
  justify-content: center!important;
  padding-right: 3px!important;
}

.export_offer {
  text-decoration: underline;
  cursor: pointer;
  color: black!important;
  text-align: center;
  display: block;
  font-size: 18px;
}

.offer_code_name {
  display: inline-block;
  max-width: calc(100% - 18px);
  text-overflow: ellipsis;
  overflow: clip;
}

.col_offer_code .text_holder {
  text-overflow: initial!important;
}

.codes-display {
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.original-fee {
  color: #6c757d;
  white-space: nowrap;
  font-style: italic;
  line-height: 15px;
  font-size: 0.9em;
}

.discount-code-text {
  color: red;
  font-style: italic;
  white-space: nowrap;
  font-size: 0.9em;
}

.lock-icon-container {
  background-image: url("../img/lock.svg");
  background-size: cover;
  width: 18px;
  height: 18px;
  cursor: default!important;
}

.locked-membership-info-box .lock-icon-container-relative {
  position: relative!important;
  transform: translateY(3px);
  margin-left: 0!important;
}

.locked-membership-info-box {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 20px 20px;
  width: 230px;
  position: absolute;
  right: 40px;
  top: 166px;
}

.locked-membership-info-box .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.locked-info-box-add-golfer {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 15px 25px;
  width: 303px;
  position: absolute;
  right: 48px;
  top: 295px;
}

.locked-info-box-add-golfer .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.locked-info-box-existing-golfer {
  border: 1px solid rgb(239, 239, 239);
  color: rgb(153, 153, 153);
  padding: 5px 20px 15px 25px;
  width: 307px;
  margin-left: 5px;
}

.locked-info-box-existing-golfer .material-icons-outlined {
  position: absolute;
  margin-left: -20px;
  margin-top: -2px;
}

.membership-description-dropdown {
  max-width: 90px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}

.change-membership-dropdown {
  max-width: 250px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: end;
}

.memberships-select-container .react-select__option--is-disabled {
  background-color: #e2e2e2c4!important;
}
