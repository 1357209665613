@use "sass:color";
@use "../utils/mixins";
@use "../utils/variables";

.btn {

    @include mixins.breakpoint() {

        width: auto;
        height: 40px;

        border: none;

        font-size: 16px;
        line-height: 40px;
        font-weight: variables.$medium;
        font-family: 'National',sans-serif;

        cursor: pointer;

        border-radius: 4px;

        text-align: center;
		text-transform: capitalize;
        text-decoration: none!important;

        padding: 0 16px;

        @include mixins.user-select(none);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        white-space: nowrap;

    }

	&.label {
		justify-content: flex-start;
		padding: 0;
		text-align: left;

		span {
			text-decoration: underline;
			color: initial;
		}


	}

    .material-icons-outlined {

        @include mixins.breakpoint() {

            width: 24px;
            height: 32px;

            line-height: 32px;
            text-align: center;

            margin: 0 0 0 8px;
        }

        &.no-margin {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }

    }

    &.align-to-inputs {
        @include mixins.breakpoint() {
            margin-top: 24px;
        }
    }

    &.full,
    &:only-of-type {

        @include mixins.breakpoint() {

            width: 100%;

        }

    }

    &.large {
        @include mixins.breakpoint() {

            font-size: 24px;
            line-height: 60px;

            height: 60px;

        }
    }

	&.small {
		font-size:12px;
		height:30px;
		line-height: 30px;
	}

  &.round {
      @include mixins.breakpoint() {

        width: 40px;
        border-radius: 50%;
        font-size: 50px;
        padding-bottom: 6px;
        color: white;
        font-weight: 700;
      }
  }

    &.fill {

		text-decoration: none!important;

        &.blue {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$blue;


            }

            &:hover {

                @include mixins.breakpoint() {

                    background-color: variables.$dark_midnight_blue;

                }

            }

        }

        &.gray {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$new_gray;

            }

            &:hover {

                @include mixins.breakpoint() {

                    background-color: color.adjust(variables.$medium_gray, $lightness: -30%);

                }

            }

        }

        &.green {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$light_green;

                //  ACTION BUTUTONS USED IN APP
                //  FORCING THEM TO ACOMODATE MORE

                font-size: 14px;

            }

            &:hover {

                @include mixins.breakpoint() {

                    background-color: color.adjust(variables.$light_green, $lightness: -5%);

                }

            }

        }

        &.red {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$cardinal;

            }

            &:hover {

                @include mixins.breakpoint() {

                    background-color:  color.adjust(variables.$cardinal, $lightness: -5%);

                }

            }

        }

		&.another-red {

			@include mixins.breakpoint() {

				color: variables.$white;
				background-color: #CC0000;

			}

			&:hover {

				@include mixins.breakpoint() {

					background-color: color.adjust(#CC0000, $lightness: -5%);

				}

			}

		}

    &.orange {

      @include mixins.breakpoint() {
        color: variables.$white;
        background-color: #FF9900;
      }

      &:hover {
        @include mixins.breakpoint() {
          background-color: color.adjust(#FF9900, $lightness: -5%);
        }
      }

    }

    }

    &.loading {

      @include mixins.breakpoint() {

        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: initial;
        background-image: url('../img/loader.svg');

      }

    }

    &.empty {

        @include mixins.breakpoint() {

            background-color: transparent;

            color: variables.$blue;

            padding: 0;

        }

        &:hover {

            @include mixins.breakpoint() {

                color: color.adjust(variables.$blue, $lightness: -5%);

            }

        }

    }

    &.outline {

        &.gray {

            @include mixins.breakpoint() {

                @include mixins.box-shadow(inset 0 0 0 2px variables.$random_gray);
                background-color: transparent;

            }

            &:hover {

                @include mixins.breakpoint() {
                    background-color: variables.$random_gray;
                }

            }

        }

    }

    &.disabled {

        @include mixins.breakpoint() {

            opacity: 0.25;
            pointer-events: none;

        }

    }

    //  LNK
    &.lnk {

        @include mixins.breakpoint() {

            width: auto;
            max-height: 20px;

            padding: 0;

            color: variables.$dark_gray;

            border: none;
            border-radius: 0;
            border-bottom: solid 1px variables.$dark_gray;

            font-size: 14px;
            line-height: 16px;
            font-weight: variables.$medium;

            background-color: transparent;

            text-decoration: none!important;


            display: inline-block;

        }
        &.white{
          color: variables.$white;
        }

        &:hover {

            @include mixins.breakpoint() {

                color: variables.$black;
                border-color: variables.$black;

            }

        }

		&.clean {
			text-transform: none;
			border: none;
		}

        &.footer_lnk {

            @include mixins.breakpoint() {

                margin: 0 16px;

                font-size: 16px;
                line-height: 24px;
                font-weight: variables.$regular;

                display: block;

                color: variables.$dark_midnight_blue;


                text-decoration: none;
                border-bottom: none;

            }

            &:hover {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                }

            }

        }

        &.red {
            @include mixins.breakpoint() {
                color: variables.$cardinal;
                border-bottom: solid 1px variables.$cardinal;
            }
            &:hover {
                @include mixins.breakpoint() {
                    color: color.adjust(variables.$cardinal, $lightness: -5%);
                    border-bottom: solid 1px color.adjust(variables.$cardinal, $lightness: -5%);
                }
            }
        }

    }

    //  SEARCH BUTTON
    &.search {

        @include mixins.breakpoint() {

            flex: none;

            border-radius: 50%;

            color: variables.$white;
            background-color: variables.$cardinal;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 50px;
            height: 50px;

            margin: -5px 0 0 -25px;

            position: relative;
            z-index: 9;

        }

        .material-icons-outlined {

            @include mixins.breakpoint() {
                margin: 0;
            }

        }

    }

    //  ADDR
    &.addr {

        @include mixins.breakpoint() {

            height: 64px;

            font-size: 14px;
            line-height: 20px;
            font-weight: variables.$bold;

        }

        .material-icons-outlined {

            @include mixins.breakpoint() {

                font-size: 32px;
                margin: 0 4px 0 0;

            }

            &:last-of-type:not(:only-of-type) {

                @include mixins.breakpoint() {
                    margin: 0;
                }

            }

        }

        .long__string {

            @include mixins.breakpoint() {

                flex-shrink: 1;
                text-align: left;

                margin: 0 0 0 4px;

            }

        }

    }

    //  DISABLED
    &:disabled {

        @include mixins.breakpoint() {

            opacity: 0.5;

            pointer-events: none;

        }

    }

    //  INLINE TAB
    &.inline-tab {

        @include mixins.breakpoint() {

            color: variables.$medium_gray;
            background-color: variables.$white;

            border-radius: 0;

            font-size: 14px;
            line-height: 38px;
            font-weight: variables.$bold;

            min-width: 100px;
            display: block;

            text-align: center;

            text-decoration: none;

            border: solid 1px variables.$light_gray;

        }

        &.is-active {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$dark_midnight_blue;

            }

        }

    }

}

a {
	&.btn {
		&.lnk {
			text-transform: initial!important;
			border: 0;
		}
	}
}
